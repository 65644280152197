/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionnaireStatisticsStatementDto } from './questionnaireStatisticsStatementDto';
import { TranslationsDto } from './translationsDto';
import { QuestionnaireStatisticsAnswerDto } from './questionnaireStatisticsAnswerDto';
import { Link } from './link';


export interface QuestionnaireStatisticsQuestionDto { 
    uuid?: string;
    tenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    type: QuestionnaireStatisticsQuestionDto.TypeEnum;
    question?: TranslationsDto;
    description?: TranslationsDto;
    order?: number;
    weight?: number;
    responseCount?: number;
    statements?: Array<QuestionnaireStatisticsStatementDto>;
    answers?: Array<QuestionnaireStatisticsAnswerDto>;
    links?: Array<Link>;
    normalizeStatementWeights?: boolean;
}
export namespace QuestionnaireStatisticsQuestionDto {
    export type TypeEnum = 'SINGLE_CHOICE' | 'MULTIPLE_CHOICE' | 'LICKERT_SCALE';
    export const TypeEnum = {
        SingleChoice: 'SINGLE_CHOICE' as TypeEnum,
        MultipleChoice: 'MULTIPLE_CHOICE' as TypeEnum,
        LickertScale: 'LICKERT_SCALE' as TypeEnum
    };
}


