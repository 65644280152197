import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private platformId = inject(PLATFORM_ID);

  availableLanguages = [...this.translocoService.getAvailableLangs()];

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.setLanguage(this.getLanguage());
  }

  setLanguage(language: string) {
    this.localStorageService.setLanguage(language);
    this.translocoService.setActiveLang(language);
  }

  getLanguage() {
    let browserLanguage: string | undefined;
    if (isPlatformBrowser(this.platformId)) {
      const language = navigator.language.substring(0, 2);
      browserLanguage = this.availableLanguages.includes(language)
        ? language
        : undefined;
    }
    return (
      this.localStorageService.getLanguage() ||
      this.authService.currentUserValue?.lang ||
      browserLanguage ||
      this.translocoService.getDefaultLang()
    );
  }
}
