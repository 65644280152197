/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentStatementDetailsDto } from './assessmentStatementDetailsDto';
import { AssessmentAnswerDetailsDto } from './assessmentAnswerDetailsDto';
import { TranslationsDto } from './translationsDto';
import { QuestionnaireAnswerDetailsDto } from './questionnaireAnswerDetailsDto';
import { QuestionnaireStatementDetailsDto } from './questionnaireStatementDetailsDto';
import { Link } from './link';


export interface EntityModelAssessmentQuestionDetailsDto { 
    uuid?: string;
    tenantUuid: string;
    userUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    assessmentCategoryUuid: string;
    questionnaireQuestionUuid: string;
    questionnaireQuestionType: EntityModelAssessmentQuestionDetailsDto.QuestionnaireQuestionTypeEnum;
    questionnaireQuestionQuestion?: TranslationsDto;
    questionnaireQuestionDescription?: TranslationsDto;
    questionnaireQuestionOrder?: number;
    questionnaireQuestionWeight?: number;
    score?: number;
    progress?: number;
    questionnaireStatements?: Array<QuestionnaireStatementDetailsDto>;
    questionnaireAnswers?: Array<QuestionnaireAnswerDetailsDto>;
    statements?: Array<AssessmentStatementDetailsDto>;
    answers?: Array<AssessmentAnswerDetailsDto>;
    links?: Array<Link>;
    questionnaireQuestionNormalizeStatementWeights?: boolean;
}
export namespace EntityModelAssessmentQuestionDetailsDto {
    export type QuestionnaireQuestionTypeEnum = 'SINGLE_CHOICE' | 'MULTIPLE_CHOICE' | 'LICKERT_SCALE';
    export const QuestionnaireQuestionTypeEnum = {
        SingleChoice: 'SINGLE_CHOICE' as QuestionnaireQuestionTypeEnum,
        MultipleChoice: 'MULTIPLE_CHOICE' as QuestionnaireQuestionTypeEnum,
        LickertScale: 'LICKERT_SCALE' as QuestionnaireQuestionTypeEnum
    };
}


