/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TranslationsDto } from './translationsDto';
import { Link } from './link';


export interface QuestionnaireStatementDto { 
    uuid?: string;
    tenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    questionnaireQuestionUuid: string;
    statement?: TranslationsDto;
    order?: number;
    weight?: number;
    links?: Array<Link>;
}

