/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AssessmentStatementDto } from '../model/assessmentStatementDto';
// @ts-ignore
import { EntityModelAssessmentStatementDetailsDto } from '../model/entityModelAssessmentStatementDetailsDto';
// @ts-ignore
import { PagedModelAssessmentStatementDto } from '../model/pagedModelAssessmentStatementDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateAssessmentStatementRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    assessmentCategoryUuid: string;
    assessmentQuestionUuid: string;
    assessmentStatementDto: AssessmentStatementDto;
}

export interface DeleteAssessmentStatementRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    assessmentCategoryUuid: string;
    assessmentQuestionUuid: string;
    assessmentStatementUuid: string;
}

export interface GetAssessmentStatementRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    assessmentCategoryUuid: string;
    assessmentQuestionUuid: string;
    assessmentStatementUuid: string;
    language?: string;
}

export interface GetAssessmentStatementsRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    assessmentCategoryUuid: string;
    assessmentQuestionUuid: string;
    language?: string;
    /** One-based page index (1..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: Array<string>;
}

export interface UpdateAssessmentStatementRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    assessmentCategoryUuid: string;
    assessmentQuestionUuid: string;
    assessmentStatementUuid: string;
    assessmentStatementDto: AssessmentStatementDto;
}


@Injectable({
  providedIn: 'root'
})
export class AssessmentStatementsService {

    protected basePath = 'http://localhost:8420';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAssessmentStatement(requestParameters: CreateAssessmentStatementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelAssessmentStatementDetailsDto>;
    public createAssessmentStatement(requestParameters: CreateAssessmentStatementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelAssessmentStatementDetailsDto>>;
    public createAssessmentStatement(requestParameters: CreateAssessmentStatementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelAssessmentStatementDetailsDto>>;
    public createAssessmentStatement(requestParameters: CreateAssessmentStatementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling createAssessmentStatement.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling createAssessmentStatement.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling createAssessmentStatement.');
        }
        const assessmentStageUuid = requestParameters.assessmentStageUuid;
        if (assessmentStageUuid === null || assessmentStageUuid === undefined) {
            throw new Error('Required parameter assessmentStageUuid was null or undefined when calling createAssessmentStatement.');
        }
        const assessmentCategoryUuid = requestParameters.assessmentCategoryUuid;
        if (assessmentCategoryUuid === null || assessmentCategoryUuid === undefined) {
            throw new Error('Required parameter assessmentCategoryUuid was null or undefined when calling createAssessmentStatement.');
        }
        const assessmentQuestionUuid = requestParameters.assessmentQuestionUuid;
        if (assessmentQuestionUuid === null || assessmentQuestionUuid === undefined) {
            throw new Error('Required parameter assessmentQuestionUuid was null or undefined when calling createAssessmentStatement.');
        }
        const assessmentStatementDto = requestParameters.assessmentStatementDto;
        if (assessmentStatementDto === null || assessmentStatementDto === undefined) {
            throw new Error('Required parameter assessmentStatementDto was null or undefined when calling createAssessmentStatement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "assessmentStageUuid", value: assessmentStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "assessmentCategoryUuid", value: assessmentCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "assessmentQuestionUuid", value: assessmentQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements`;
        return this.httpClient.request<EntityModelAssessmentStatementDetailsDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: assessmentStatementDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAssessmentStatement(requestParameters: DeleteAssessmentStatementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<string>;
    public deleteAssessmentStatement(requestParameters: DeleteAssessmentStatementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<string>>;
    public deleteAssessmentStatement(requestParameters: DeleteAssessmentStatementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<string>>;
    public deleteAssessmentStatement(requestParameters: DeleteAssessmentStatementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling deleteAssessmentStatement.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling deleteAssessmentStatement.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling deleteAssessmentStatement.');
        }
        const assessmentStageUuid = requestParameters.assessmentStageUuid;
        if (assessmentStageUuid === null || assessmentStageUuid === undefined) {
            throw new Error('Required parameter assessmentStageUuid was null or undefined when calling deleteAssessmentStatement.');
        }
        const assessmentCategoryUuid = requestParameters.assessmentCategoryUuid;
        if (assessmentCategoryUuid === null || assessmentCategoryUuid === undefined) {
            throw new Error('Required parameter assessmentCategoryUuid was null or undefined when calling deleteAssessmentStatement.');
        }
        const assessmentQuestionUuid = requestParameters.assessmentQuestionUuid;
        if (assessmentQuestionUuid === null || assessmentQuestionUuid === undefined) {
            throw new Error('Required parameter assessmentQuestionUuid was null or undefined when calling deleteAssessmentStatement.');
        }
        const assessmentStatementUuid = requestParameters.assessmentStatementUuid;
        if (assessmentStatementUuid === null || assessmentStatementUuid === undefined) {
            throw new Error('Required parameter assessmentStatementUuid was null or undefined when calling deleteAssessmentStatement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "assessmentStageUuid", value: assessmentStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "assessmentCategoryUuid", value: assessmentCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "assessmentQuestionUuid", value: assessmentQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements/${this.configuration.encodeParam({name: "assessmentStatementUuid", value: assessmentStatementUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<string>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssessmentStatement(requestParameters: GetAssessmentStatementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelAssessmentStatementDetailsDto>;
    public getAssessmentStatement(requestParameters: GetAssessmentStatementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelAssessmentStatementDetailsDto>>;
    public getAssessmentStatement(requestParameters: GetAssessmentStatementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelAssessmentStatementDetailsDto>>;
    public getAssessmentStatement(requestParameters: GetAssessmentStatementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling getAssessmentStatement.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling getAssessmentStatement.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling getAssessmentStatement.');
        }
        const assessmentStageUuid = requestParameters.assessmentStageUuid;
        if (assessmentStageUuid === null || assessmentStageUuid === undefined) {
            throw new Error('Required parameter assessmentStageUuid was null or undefined when calling getAssessmentStatement.');
        }
        const assessmentCategoryUuid = requestParameters.assessmentCategoryUuid;
        if (assessmentCategoryUuid === null || assessmentCategoryUuid === undefined) {
            throw new Error('Required parameter assessmentCategoryUuid was null or undefined when calling getAssessmentStatement.');
        }
        const assessmentQuestionUuid = requestParameters.assessmentQuestionUuid;
        if (assessmentQuestionUuid === null || assessmentQuestionUuid === undefined) {
            throw new Error('Required parameter assessmentQuestionUuid was null or undefined when calling getAssessmentStatement.');
        }
        const assessmentStatementUuid = requestParameters.assessmentStatementUuid;
        if (assessmentStatementUuid === null || assessmentStatementUuid === undefined) {
            throw new Error('Required parameter assessmentStatementUuid was null or undefined when calling getAssessmentStatement.');
        }
        const language = requestParameters.language;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (language !== undefined && language !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>language, 'language');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "assessmentStageUuid", value: assessmentStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "assessmentCategoryUuid", value: assessmentCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "assessmentQuestionUuid", value: assessmentQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements/${this.configuration.encodeParam({name: "assessmentStatementUuid", value: assessmentStatementUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<EntityModelAssessmentStatementDetailsDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssessmentStatements(requestParameters: GetAssessmentStatementsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PagedModelAssessmentStatementDto>;
    public getAssessmentStatements(requestParameters: GetAssessmentStatementsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PagedModelAssessmentStatementDto>>;
    public getAssessmentStatements(requestParameters: GetAssessmentStatementsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PagedModelAssessmentStatementDto>>;
    public getAssessmentStatements(requestParameters: GetAssessmentStatementsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling getAssessmentStatements.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling getAssessmentStatements.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling getAssessmentStatements.');
        }
        const assessmentStageUuid = requestParameters.assessmentStageUuid;
        if (assessmentStageUuid === null || assessmentStageUuid === undefined) {
            throw new Error('Required parameter assessmentStageUuid was null or undefined when calling getAssessmentStatements.');
        }
        const assessmentCategoryUuid = requestParameters.assessmentCategoryUuid;
        if (assessmentCategoryUuid === null || assessmentCategoryUuid === undefined) {
            throw new Error('Required parameter assessmentCategoryUuid was null or undefined when calling getAssessmentStatements.');
        }
        const assessmentQuestionUuid = requestParameters.assessmentQuestionUuid;
        if (assessmentQuestionUuid === null || assessmentQuestionUuid === undefined) {
            throw new Error('Required parameter assessmentQuestionUuid was null or undefined when calling getAssessmentStatements.');
        }
        const language = requestParameters.language;
        const page = requestParameters.page;
        const size = requestParameters.size;
        const sort = requestParameters.sort;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (language !== undefined && language !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>language, 'language');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sort) {
            sort.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'sort');
            })
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "assessmentStageUuid", value: assessmentStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "assessmentCategoryUuid", value: assessmentCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "assessmentQuestionUuid", value: assessmentQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements`;
        return this.httpClient.request<PagedModelAssessmentStatementDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAssessmentStatement(requestParameters: UpdateAssessmentStatementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelAssessmentStatementDetailsDto>;
    public updateAssessmentStatement(requestParameters: UpdateAssessmentStatementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelAssessmentStatementDetailsDto>>;
    public updateAssessmentStatement(requestParameters: UpdateAssessmentStatementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelAssessmentStatementDetailsDto>>;
    public updateAssessmentStatement(requestParameters: UpdateAssessmentStatementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling updateAssessmentStatement.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling updateAssessmentStatement.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling updateAssessmentStatement.');
        }
        const assessmentStageUuid = requestParameters.assessmentStageUuid;
        if (assessmentStageUuid === null || assessmentStageUuid === undefined) {
            throw new Error('Required parameter assessmentStageUuid was null or undefined when calling updateAssessmentStatement.');
        }
        const assessmentCategoryUuid = requestParameters.assessmentCategoryUuid;
        if (assessmentCategoryUuid === null || assessmentCategoryUuid === undefined) {
            throw new Error('Required parameter assessmentCategoryUuid was null or undefined when calling updateAssessmentStatement.');
        }
        const assessmentQuestionUuid = requestParameters.assessmentQuestionUuid;
        if (assessmentQuestionUuid === null || assessmentQuestionUuid === undefined) {
            throw new Error('Required parameter assessmentQuestionUuid was null or undefined when calling updateAssessmentStatement.');
        }
        const assessmentStatementUuid = requestParameters.assessmentStatementUuid;
        if (assessmentStatementUuid === null || assessmentStatementUuid === undefined) {
            throw new Error('Required parameter assessmentStatementUuid was null or undefined when calling updateAssessmentStatement.');
        }
        const assessmentStatementDto = requestParameters.assessmentStatementDto;
        if (assessmentStatementDto === null || assessmentStatementDto === undefined) {
            throw new Error('Required parameter assessmentStatementDto was null or undefined when calling updateAssessmentStatement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "assessmentStageUuid", value: assessmentStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "assessmentCategoryUuid", value: assessmentCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "assessmentQuestionUuid", value: assessmentQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements/${this.configuration.encodeParam({name: "assessmentStatementUuid", value: assessmentStatementUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<EntityModelAssessmentStatementDetailsDto>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: assessmentStatementDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
