/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TranslationsDto } from './translationsDto';
import { Link } from './link';
import { AssessmentQuestionDetailsDto } from './assessmentQuestionDetailsDto';


export interface AssessmentCategoryDetailsDto { 
    uuid?: string;
    tenantUuid: string;
    userUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    questionnaireCategoryUuid: string;
    questionnaireCategoryName?: TranslationsDto;
    questionnaireCategoryOrder?: number;
    questionnaireCategoryRelevance?: number;
    questionnaireCategoryWeight?: number;
    score?: number;
    progress?: number;
    questions?: Array<AssessmentQuestionDetailsDto>;
    links?: Array<Link>;
}

