/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { EntityModelQuestionnaireStatementDetailsDto } from '../model/entityModelQuestionnaireStatementDetailsDto';
// @ts-ignore
import { PagedModelQuestionnaireStatementDto } from '../model/pagedModelQuestionnaireStatementDto';
// @ts-ignore
import { QuestionnaireStatementDto } from '../model/questionnaireStatementDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateQuestionnaireStatementRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    questionnaireQuestionUuid: string;
    questionnaireStatementDto: QuestionnaireStatementDto;
}

export interface DeleteQuestionnaireStatementRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    questionnaireQuestionUuid: string;
    questionnaireStatementUuid: string;
}

export interface GetQuestionnaireStatementRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    questionnaireQuestionUuid: string;
    questionnaireStatementUuid: string;
    language?: string;
}

export interface GetQuestionnaireStatementsRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    questionnaireQuestionUuid: string;
    language?: string;
    /** One-based page index (1..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: Array<string>;
}

export interface UpdateQuestionnaireStatementRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    questionnaireQuestionUuid: string;
    questionnaireStatementUuid: string;
    questionnaireStatementDto: QuestionnaireStatementDto;
}


@Injectable({
  providedIn: 'root'
})
export class QuestionnaireStatementsService {

    protected basePath = 'http://localhost:8420';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createQuestionnaireStatement(requestParameters: CreateQuestionnaireStatementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelQuestionnaireStatementDetailsDto>;
    public createQuestionnaireStatement(requestParameters: CreateQuestionnaireStatementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelQuestionnaireStatementDetailsDto>>;
    public createQuestionnaireStatement(requestParameters: CreateQuestionnaireStatementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelQuestionnaireStatementDetailsDto>>;
    public createQuestionnaireStatement(requestParameters: CreateQuestionnaireStatementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling createQuestionnaireStatement.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling createQuestionnaireStatement.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling createQuestionnaireStatement.');
        }
        const questionnaireCategoryUuid = requestParameters.questionnaireCategoryUuid;
        if (questionnaireCategoryUuid === null || questionnaireCategoryUuid === undefined) {
            throw new Error('Required parameter questionnaireCategoryUuid was null or undefined when calling createQuestionnaireStatement.');
        }
        const questionnaireQuestionUuid = requestParameters.questionnaireQuestionUuid;
        if (questionnaireQuestionUuid === null || questionnaireQuestionUuid === undefined) {
            throw new Error('Required parameter questionnaireQuestionUuid was null or undefined when calling createQuestionnaireStatement.');
        }
        const questionnaireStatementDto = requestParameters.questionnaireStatementDto;
        if (questionnaireStatementDto === null || questionnaireStatementDto === undefined) {
            throw new Error('Required parameter questionnaireStatementDto was null or undefined when calling createQuestionnaireStatement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "questionnaireCategoryUuid", value: questionnaireCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "questionnaireQuestionUuid", value: questionnaireQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements`;
        return this.httpClient.request<EntityModelQuestionnaireStatementDetailsDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: questionnaireStatementDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteQuestionnaireStatement(requestParameters: DeleteQuestionnaireStatementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<string>;
    public deleteQuestionnaireStatement(requestParameters: DeleteQuestionnaireStatementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<string>>;
    public deleteQuestionnaireStatement(requestParameters: DeleteQuestionnaireStatementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<string>>;
    public deleteQuestionnaireStatement(requestParameters: DeleteQuestionnaireStatementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling deleteQuestionnaireStatement.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling deleteQuestionnaireStatement.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling deleteQuestionnaireStatement.');
        }
        const questionnaireCategoryUuid = requestParameters.questionnaireCategoryUuid;
        if (questionnaireCategoryUuid === null || questionnaireCategoryUuid === undefined) {
            throw new Error('Required parameter questionnaireCategoryUuid was null or undefined when calling deleteQuestionnaireStatement.');
        }
        const questionnaireQuestionUuid = requestParameters.questionnaireQuestionUuid;
        if (questionnaireQuestionUuid === null || questionnaireQuestionUuid === undefined) {
            throw new Error('Required parameter questionnaireQuestionUuid was null or undefined when calling deleteQuestionnaireStatement.');
        }
        const questionnaireStatementUuid = requestParameters.questionnaireStatementUuid;
        if (questionnaireStatementUuid === null || questionnaireStatementUuid === undefined) {
            throw new Error('Required parameter questionnaireStatementUuid was null or undefined when calling deleteQuestionnaireStatement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "questionnaireCategoryUuid", value: questionnaireCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "questionnaireQuestionUuid", value: questionnaireQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements/${this.configuration.encodeParam({name: "questionnaireStatementUuid", value: questionnaireStatementUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<string>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionnaireStatement(requestParameters: GetQuestionnaireStatementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelQuestionnaireStatementDetailsDto>;
    public getQuestionnaireStatement(requestParameters: GetQuestionnaireStatementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelQuestionnaireStatementDetailsDto>>;
    public getQuestionnaireStatement(requestParameters: GetQuestionnaireStatementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelQuestionnaireStatementDetailsDto>>;
    public getQuestionnaireStatement(requestParameters: GetQuestionnaireStatementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling getQuestionnaireStatement.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling getQuestionnaireStatement.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling getQuestionnaireStatement.');
        }
        const questionnaireCategoryUuid = requestParameters.questionnaireCategoryUuid;
        if (questionnaireCategoryUuid === null || questionnaireCategoryUuid === undefined) {
            throw new Error('Required parameter questionnaireCategoryUuid was null or undefined when calling getQuestionnaireStatement.');
        }
        const questionnaireQuestionUuid = requestParameters.questionnaireQuestionUuid;
        if (questionnaireQuestionUuid === null || questionnaireQuestionUuid === undefined) {
            throw new Error('Required parameter questionnaireQuestionUuid was null or undefined when calling getQuestionnaireStatement.');
        }
        const questionnaireStatementUuid = requestParameters.questionnaireStatementUuid;
        if (questionnaireStatementUuid === null || questionnaireStatementUuid === undefined) {
            throw new Error('Required parameter questionnaireStatementUuid was null or undefined when calling getQuestionnaireStatement.');
        }
        const language = requestParameters.language;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (language !== undefined && language !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>language, 'language');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "questionnaireCategoryUuid", value: questionnaireCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "questionnaireQuestionUuid", value: questionnaireQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements/${this.configuration.encodeParam({name: "questionnaireStatementUuid", value: questionnaireStatementUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<EntityModelQuestionnaireStatementDetailsDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionnaireStatements(requestParameters: GetQuestionnaireStatementsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PagedModelQuestionnaireStatementDto>;
    public getQuestionnaireStatements(requestParameters: GetQuestionnaireStatementsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PagedModelQuestionnaireStatementDto>>;
    public getQuestionnaireStatements(requestParameters: GetQuestionnaireStatementsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PagedModelQuestionnaireStatementDto>>;
    public getQuestionnaireStatements(requestParameters: GetQuestionnaireStatementsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling getQuestionnaireStatements.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling getQuestionnaireStatements.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling getQuestionnaireStatements.');
        }
        const questionnaireCategoryUuid = requestParameters.questionnaireCategoryUuid;
        if (questionnaireCategoryUuid === null || questionnaireCategoryUuid === undefined) {
            throw new Error('Required parameter questionnaireCategoryUuid was null or undefined when calling getQuestionnaireStatements.');
        }
        const questionnaireQuestionUuid = requestParameters.questionnaireQuestionUuid;
        if (questionnaireQuestionUuid === null || questionnaireQuestionUuid === undefined) {
            throw new Error('Required parameter questionnaireQuestionUuid was null or undefined when calling getQuestionnaireStatements.');
        }
        const language = requestParameters.language;
        const page = requestParameters.page;
        const size = requestParameters.size;
        const sort = requestParameters.sort;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (language !== undefined && language !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>language, 'language');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sort) {
            sort.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'sort');
            })
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "questionnaireCategoryUuid", value: questionnaireCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "questionnaireQuestionUuid", value: questionnaireQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements`;
        return this.httpClient.request<PagedModelQuestionnaireStatementDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateQuestionnaireStatement(requestParameters: UpdateQuestionnaireStatementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelQuestionnaireStatementDetailsDto>;
    public updateQuestionnaireStatement(requestParameters: UpdateQuestionnaireStatementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelQuestionnaireStatementDetailsDto>>;
    public updateQuestionnaireStatement(requestParameters: UpdateQuestionnaireStatementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelQuestionnaireStatementDetailsDto>>;
    public updateQuestionnaireStatement(requestParameters: UpdateQuestionnaireStatementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling updateQuestionnaireStatement.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling updateQuestionnaireStatement.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling updateQuestionnaireStatement.');
        }
        const questionnaireCategoryUuid = requestParameters.questionnaireCategoryUuid;
        if (questionnaireCategoryUuid === null || questionnaireCategoryUuid === undefined) {
            throw new Error('Required parameter questionnaireCategoryUuid was null or undefined when calling updateQuestionnaireStatement.');
        }
        const questionnaireQuestionUuid = requestParameters.questionnaireQuestionUuid;
        if (questionnaireQuestionUuid === null || questionnaireQuestionUuid === undefined) {
            throw new Error('Required parameter questionnaireQuestionUuid was null or undefined when calling updateQuestionnaireStatement.');
        }
        const questionnaireStatementUuid = requestParameters.questionnaireStatementUuid;
        if (questionnaireStatementUuid === null || questionnaireStatementUuid === undefined) {
            throw new Error('Required parameter questionnaireStatementUuid was null or undefined when calling updateQuestionnaireStatement.');
        }
        const questionnaireStatementDto = requestParameters.questionnaireStatementDto;
        if (questionnaireStatementDto === null || questionnaireStatementDto === undefined) {
            throw new Error('Required parameter questionnaireStatementDto was null or undefined when calling updateQuestionnaireStatement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "questionnaireCategoryUuid", value: questionnaireCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/questions/${this.configuration.encodeParam({name: "questionnaireQuestionUuid", value: questionnaireQuestionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/statements/${this.configuration.encodeParam({name: "questionnaireStatementUuid", value: questionnaireStatementUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<EntityModelQuestionnaireStatementDetailsDto>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: questionnaireStatementDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
