/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentAnswerDetailsDto } from './assessmentAnswerDetailsDto';
import { TranslationsDto } from './translationsDto';
import { Link } from './link';
import { QuestionnaireStatementAnswerDetailsDto } from './questionnaireStatementAnswerDetailsDto';


export interface AssessmentStatementDetailsDto { 
    uuid?: string;
    tenantUuid: string;
    userUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    assessmentCategoryUuid: string;
    assessmentQuestionUuid: string;
    questionnaireStatementUuid: string;
    questionnaireStatementStatement?: TranslationsDto;
    questionnaireStatementOrder?: number;
    questionnaireStatementWeight?: number;
    score?: number;
    questionnaireStatementAnswers?: Array<QuestionnaireStatementAnswerDetailsDto>;
    answers?: Array<AssessmentAnswerDetailsDto>;
    links?: Array<Link>;
}

