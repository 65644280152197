/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TranslationDto } from './translationDto';


export interface TranslationsDto { 
    language?: string;
    value?: string;
    translations?: Array<TranslationDto>;
}

